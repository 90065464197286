import { render, staticRenderFns } from "./ResellerRegistration.vue?vue&type=template&id=c9fd65fa&scoped=true&"
import script from "./ResellerRegistration.vue?vue&type=script&lang=js&"
export * from "./ResellerRegistration.vue?vue&type=script&lang=js&"
import style0 from "./ResellerRegistration.vue?vue&type=style&index=0&id=c9fd65fa&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9fd65fa",
  null
  
)

export default component.exports