<template>
  <div class="grow center wrap">
    <form @submit.prevent="post" class="container center d-col">
      <s-logo />
      <h1 v-if="$mq != 'sm'" class="d-flex green--text font-48 wrap center">
        <div class="weight-300">secur</div>
        <div class="bold">Meet</div>
        <div class="bold ml-10">Partner</div>
        <div class="bold ml-10">Portal</div>
      </h1>
      <div class="missingText mb-20" :class="{ textRed: message.status == 'error' }">
        <div v-if="message.errorCode">
          {{ $t("server." + message.errorCode) }}
        </div>
      </div>
      <div class="w100">Name <span class="red--text font-14 ml-10">Required (*)</span></div>
      <s-text-field block v-model="firstName" :label="$t('registration.first_name') + ' *'"></s-text-field>
      <s-text-field block v-model="lastName" :label="$t('registration.last_name') + ' *'"></s-text-field>
      <s-text-field block v-model="email" type="text" :label="'Personal Email' + ' *'"></s-text-field>
      <s-text-field block v-model="companyName" type="text" :label="'Company Name' + ' *'"></s-text-field>
      <div class="w100 mt-30">Password <span class="red--text font-14 ml-10">Required (*)</span></div>
      <s-text-field block bar password v-model="password" type="password" :label="$t('registration.password') + ' *'"></s-text-field>
      <s-text-field block password v-model="confirmPassword" type="password" :label="$t('registration.confirm_password') + ' *'"></s-text-field>
      <div class="w100 mt-30">Email <span class="opacity-60 font-14 ml-10">Optional</span></div>
      <s-text-field block v-model="emailContact" type="text" :label="'Contact Email'"></s-text-field>
      <s-text-field block v-model="emailBilling" type="text" :label="'Billing Email'"></s-text-field>
      <s-text-field block v-model="emailLegal" type="text" :label="'Legal Email'"></s-text-field>
      <div class="w100 mt-30">Address <span class="opacity-60 font-14 ml-10">Optional</span></div>
      <s-text-field block v-model="contactAddress1" type="text" :label="'Address 1'"></s-text-field>
      <s-text-field block v-model="contactAddress2" type="text" :label="'Address 2'"></s-text-field>
      <s-text-field block v-model="contactZIP" type="text" :label="'Zip'"></s-text-field>
      <s-text-field block v-model="contactCity" type="text" :label="'City'"></s-text-field>
      <s-text-field block v-model="contactState" type="text" :label="'State'"></s-text-field>
      <s-dropdown :countries="countries" :height="40" v-model="contactCountry" class="w100 mt-20" placeholder="Country"></s-dropdown>
      <div class="w100 mt-30">Survey <span class="opacity-60 font-14 ml-10">Optional</span></div>
      <s-dropdown :countries="surveyOptions" :height="40" v-model="contactCountry" class="w100 mt-20"></s-dropdown>
      <div class="d-flex space-between w100 mt-15 mb-10 center wrap" ref="terms">
        <div class="center gap-10" @click="check = !check">
          <s-checkbox v-model="check"></s-checkbox>
          <button class="green--text pl-0 font-12 pointer d-flex" type="button">
            <div>{{ $t("registration.terms") }}</div>
            <div @click="tns" class="underline ml-5">
              {{ $t("registration.tos") }}
            </div>
          </button>
        </div>
      </div>
      <s-btn type="submit" block class="green mt-15" height="40">
        {{ $t("registration.submit") }}
      </s-btn>
    </form>
    <div class="tns_container center" ref="modal_page" v-show="terms">
      <div class="tns relative" ref="modal_window" v-click-outside="closeModal">
        <div class="w100 justify-end d-flex">
          <button>
            <s-icon @click.native="closeModal" color="grey">close</s-icon>
          </button>
        </div>
        <Terms />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Terms from "./Terms";

export default {
  components: {
    Terms,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      message: "",
      check: false,
      companyName: "",
      emailContact: "",
      emailBilling: "",
      emailLegal: "",
      contactAddress1: "",
      contactAddress2: "",
      contactZIP: "",
      contactCity: "",
      contactState: "",
      contactCountry: 0,
    };
  },
  methods: {
    tns() {
      this.check = !this.check;
      this.$store.commit("modals/terms", true);
    },
    validatePassword() {
      if (!this.email) return;
      if (!this.companyName) return;
      if (this.password == this.confirmPassword) return false;
      this.message = { errorCode: "passwords_dont_match", status: "error" };
      return true;
    },
    closeModal() {
      this.$store.commit("modals/terms", false);
    },
    isValidEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validatename() {
      var exr = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/;
      var first = this.firstName.match(exr);
      var last = this.lastName.match(exr);
      let code = "";
      if (!last) code = "invalid_lastname";
      if (!first) code = "invalid_firstname";
      if (this.lastName.length < 2) code = "min_last_name";
      if (this.firstName.length < 2) code = "min_first_name";
      if (this.lastName.length == 0) code = "enter_last_name";
      if (this.firstName.length == 0) code = "enter_first_name";
      if (!code) return true;
      this.message = { errorCode: code, status: "error" };
    },
    async post() {
      if (!this.validatename()) return;
      if (this.validatePassword()) return;


      let data = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        check: this.check,
        company: {
          companyName: this.companyName,
          emailContact: this.emailContact,
          emailBilling: this.emailBilling,
          emailLegal: this.emailLegal,
          contactAddress1: this.contactAddress1,
          contactAddress2: this.contactAddress2,
          contactZIP: this.contactZIP,
          contactCity: this.contactCity,
          contactState: this.contactState,
          contactCountry: this.contactCountry,
        },
      };
      let response = await this.$store.dispatch("auth/reSellerRegister", data);
      this.message = response;

      if (response.status == "ok") {
        let msg = { errorCode: "agree", status: "error" };
        if (!this.check) return (this.message = msg);
        this.$store.dispatch("user/getResellerInformation");
        this.$store.dispatch("products/getResellerProducts");
        this.$router.push("/partners");
      }
    },
  },
  computed: {
    surveyOptions() {
      return [
        { name: 'Search Engine', id: 1 },
        { name: 'Google Ads', id: 2 },
        { name: 'Facebook Ads', id: 3 },
        { name: 'Word of mouth', id: 4 },
        { name: 'Colleague', id: 5 },
        { name: 'Friend', id: 6 }
      ]
    },
    ...mapState("modals", ["terms"]),
    ...mapState("user", ["countries"]),
  },
  mounted() {
    let el = this.$refs.terms;
    this.$store.dispatch("user/getCountries");
  },
};
</script>


<style scoped lang="less">
.container {
  width: 100%;
  max-width: 400px;
  margin: 10px;
}

.textRed {
  color: #df5151;
}

.tns_container {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 3;
}

.tns {
  max-width: 650px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
  overflow-y: scroll;
  max-height: 700px;
  height: 700px;
  max-height: 100vh;
}

@media only screen and (max-width: 576px) {
  .tns {
    padding: 5px 0 5px 5px;
  }
}
</style>
